#homepage_map .wrapper-home {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#homepage_map .map {
  width: 100%;
  height: 600px;
  background-color: #e8e8e8;
  margin-bottom: 0;
}
@media screen and (max-width: 800px) {
  #homepage_map .map {
    height: 450px;
  }
}
#homepage_map .homepage_interactive_map .card__datetime {
  justify-content: center;
}
#homepage_map #interactive_map .district-number {
  color: #666;
  border: 2px solid transparent;
  background-color: rgba(255, 255, 255, 0.4);
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 14px;
}
#homepage_map #interactive_map .district-number:hover {
  border-color: rgba(255, 255, 255, 0.4);
}
#homepage_map #interactive_map .district-number:focus, #homepage_map #interactive_map .district-number:active {
  color: rgb(239, 96, 77);
  border-color: rgb(239, 96, 77);
}
#homepage_map #interactive_map .marker-cluster {
  background-clip: padding-box;
  border-radius: 7px;
}
#homepage_map #interactive_map .marker-cluster div {
  background-color: rgb(239, 96, 77);
  color: white;
  margin: 0;
  font-weight: bold;
  font-size: 9px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 7px;
}
#homepage_map .svg-icon {
  opacity: 0.75;
}
#homepage_map .svg-icon:hover {
  opacity: 1;
}